import { Capacitor } from "@capacitor/core";
import { Col, Row, Space, theme } from "antd";
import React from "react";

import backgroundImageSrc from "assets/images/background.png";
import buttonAppStoreImageSrc from "assets/images/button-app-store.png";
import buttonGooglePlayImageSrc from "assets/images/button-google-play.png";
import {
  Button,
  Layout,
  Link,
  Typography,
  TypographySizes,
} from "components/antd";
import { appLinkAppleStore, appLinkGooglePlay } from "helpers/constants";
import { useBreakpoint } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { Global, useGlobalLayoutStyles } from "styles/global";
import { sizeXXL } from "styles/variables";

import Header from "./Header";

const backgroundImageRatio = "35.78%";
const badgeButtonHeight = 50;
const showFixedBackground = true;

const StyledBackground = styled.div(
  showFixedBackground && {
    backgroundImage: `url(${backgroundImageSrc})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    bottom: 0,
    paddingBottom: backgroundImageRatio,
    position: "fixed",
    width: "100%",
    zIndex: -1,
  }
);

const StyledBadge = styled.img({
  height: badgeButtonHeight * 0.5,
});

const StyledBadgeButton = styled(Button)({
  borderRadius: badgeButtonHeight * 0.3,
  height: badgeButtonHeight,
  paddingBottom: badgeButtonHeight * 0.25,
  paddingLeft: badgeButtonHeight * 0.4,
  paddingRight: badgeButtonHeight * 0.4,
  paddingTop: badgeButtonHeight * 0.25,
});

const StyledCol = styled(Col)(
  ({ styledProps: { marginBottom, textAlign } }: StyledProps) => ({
    marginBottom,
    textAlign,
  })
);

const StyledLayout = styled(Layout)(
  ({ styledProps: { paddingBottom, paddingTop } }: StyledProps) => ({
    paddingBottom,
    paddingTop,
    ...(showFixedBackground && {
      backgroundColor: "transparent",
    }),
  })
);

type SinglePageLayoutType = {
  children: React.ReactNode;
  isAppDownloadButtonsVisible?: boolean;
  isCentered?: boolean;
  isCompact?: boolean;
  title?: string;
};

const SinglePageLayout = ({
  children,
  isAppDownloadButtonsVisible,
  isCentered,
  isCompact,
  title,
}: SinglePageLayoutType) => {
  const { isDesktop } = useBreakpoint();
  const globalLayoutStyles = useGlobalLayoutStyles();
  const { token } = theme.useToken();

  const isLargeHeader = !isCompact && isDesktop;
  const isWeb = Capacitor.getPlatform() === "web";

  return (
    <>
      <Global styles={globalLayoutStyles} />

      <StyledBackground />

      <StyledLayout
        isFullHeight
        styledProps={{
          paddingBottom: token.paddingXL,
          paddingTop: isLargeHeader ? 70 : 0,
        }}
      >
        <Space direction="vertical" size={sizeXXL}>
          <Header isLarge={isLargeHeader} />

          <Row align="middle" justify="center">
            {title && (
              <StyledCol
                span={20}
                styledProps={{
                  marginBottom: token.marginMD,
                  textAlign: "center",
                }}
              >
                <Typography.Title>{title}</Typography.Title>
              </StyledCol>
            )}

            <StyledCol
              span={20}
              styledProps={{ textAlign: isCentered && "center" }}
            >
              {children}
            </StyledCol>
          </Row>

          {isAppDownloadButtonsVisible && isWeb && (
            <Space
              direction="vertical"
              size="large"
              style={{ textAlign: "center" }}
            >
              <Typography.Text size={TypographySizes.LG} strong>
                Or get our free app
              </Typography.Text>

              <Space size="middle" style={{ justifyContent: "center" }}>
                <Link to={appLinkAppleStore}>
                  <StyledBadgeButton>
                    <StyledBadge src={buttonAppStoreImageSrc} />
                  </StyledBadgeButton>
                </Link>
                <Link to={appLinkGooglePlay}>
                  <StyledBadgeButton>
                    <StyledBadge src={buttonGooglePlayImageSrc} />
                  </StyledBadgeButton>
                </Link>
              </Space>
            </Space>
          )}
        </Space>
      </StyledLayout>
    </>
  );
};

export default SinglePageLayout;
