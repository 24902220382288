import { EditOutlined, PushpinOutlined } from "@ant-design/icons";
import { Col, Row, theme } from "antd";
import type { TabsProps } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import TabSwitcher from "components/TabSwitcher";
import Window from "components/Window";
import { Space, Typography } from "components/antd";
import { Button } from "components/antd";
import {
  useBreakpoint,
  useDispatch,
  useNavigateToPath,
  usePath,
  useSelector,
} from "hooks";
import { LayoutContext } from "layouts/Main";
import { ProtectedPaths } from "routes";
import { fetchJoinedEvents } from "store/appSlice";
import { selectAccountId } from "store/selectors";
import EditProfile from "views/Edit/Profile";
import PreviewProfile, { maxWidth } from "views/PreviewProfile";

type ProfileProps = {
  children?: React.ReactNode;
};

const Profile = ({ children }: ProfileProps) => {
  const { isDesktop } = useBreakpoint();
  const { isGeneralViewVisible } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToEventProfile = useNavigateToPath(ProtectedPaths.EventProfile);
  const navigateToEventProfileEdit = useNavigateToPath(
    ProtectedPaths.EventProfileEdit
  );
  const navigateToEventProfileInterests = useNavigateToPath(
    ProtectedPaths.EventProfileInterests
  );
  const eventProfileEditPath = usePath(ProtectedPaths.EventProfileEdit);
  const eventProfileInterestsPath = usePath(
    ProtectedPaths.EventProfileInterests
  );
  const accountId = useSelector(selectAccountId);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const { token } = theme.useToken();

  useEffect(() => {
    setIsWindowOpen(children && !isGeneralViewVisible);
  }, [children, isGeneralViewVisible]);

  useEffect(() => {
    dispatch(fetchJoinedEvents(accountId));
  }, [accountId, dispatch]);

  const tabItems: TabsProps["items"] = [
    {
      key: eventProfileEditPath,
      label: "Edit info",
    },
    {
      key: eventProfileInterestsPath,
      label: "My expertise",
    },
  ];

  const activeKey = location.pathname.includes(eventProfileEditPath)
    ? eventProfileEditPath
    : eventProfileInterestsPath; // TODO: Replace with a universal method of removing params from pathname

  const defaultView = {
    component: <EditProfile />,
    key: eventProfileEditPath,
  };

  const buttons = [
    {
      icon: <EditOutlined />,
      label: "Edit profile",
      onClick: navigateToEventProfileEdit,
    },
    {
      icon: <PushpinOutlined />,
      label: "My expertise",
      onClick: navigateToEventProfileInterests,
    },
  ];

  return isDesktop ? (
    <>
      <TabSwitcher
        activeKey={children ? activeKey : defaultView.key}
        items={tabItems}
        onChange={(key: string) => navigate(key)}
      />

      <Row>
        <Col span={11}>{children || defaultView.component}</Col>
        <Col offset={2} span={11}>
          <PreviewProfile />
        </Col>
      </Row>
    </>
  ) : (
    <Space direction="vertical" size="middle">
      <Typography.Title style={{ margin: 0, textAlign: "center" }}>
        Your profile
      </Typography.Title>

      <Space
        style={{
          justifyContent: "center",
          margin: "auto",
          marginBottom: token.marginLG,
          maxWidth,
        }}
      >
        {buttons.map(({ icon, label, onClick }, index) => (
          <Button
            key={index}
            onClick={onClick}
            shape="round"
            size="large"
            type="default"
          >
            {label} {icon}
          </Button>
        ))}
      </Space>

      <PreviewProfile />

      <Window
        isOpen={isWindowOpen}
        onAfterClose={navigateToEventProfile}
        onClose={() => setIsWindowOpen(false)}
      >
        {children}
      </Window>
    </Space>
  );
};

export default Profile;
