import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useContext, useEffect, useMemo, useState } from "react";

import Window from "components/Window";
import {
  formatUserFullName,
  getDateAtMidnight,
  getValidDates,
  getValidTimes,
  isValue,
} from "helpers";
import {
  humanReadableDateAndTimeFormat,
  humanReadableDateFormat,
} from "helpers/constants";
import { MeetingTypes } from "helpers/enums";
import { useJourney, useSelector, useUnmountOnAfterClose } from "hooks";
import type { UnmountOnAfterCloseProps } from "hooks";
import { JourneyContext, JourneyContextProvider } from "hooks/useJourney";
import type { ProfileProps, TimeProps } from "store";
import { selectEvent } from "store/selectors";

import Confirmation from "./Content/Confirmation";
import DateSelector from "./Content/DateSelector";
import Note from "./Content/Note";
import TimeSelector, { TimeSelectorFooter } from "./Content/TimeSelector";
import TypeSelector from "./Content/TypeSelector";
import Footer from "./Footer";
import Header from "./Header";
import { useSubmit } from "./useSubmit";

dayjs.extend(isBetween);

enum JourneySteps {
  "TypeSelector",
  "DateSelector",
  "TimeSelector",
  "Note",
  "Confirmation",
}

const defaultType: MeetingTypes = MeetingTypes.Virtual;
const initialStep: JourneySteps = JourneySteps.TypeSelector;
const initialDateAndTime: Dayjs = getDateAtMidnight(dayjs());
const initialType: MeetingTypes = undefined;

type NewMeetingWindowProps = {
  isOpen: boolean;
  onClose: () => void;
  user: ProfileProps;
};

const NewMeetingWindow = ({
  isOpen,
  onAfterClose,
  onClose,
  user,
}: NewMeetingWindowProps & UnmountOnAfterCloseProps) => {
  const { activeStepIndex: activeStep, setActiveStepIndex } =
    useContext(JourneyContext);
  const event = useSelector(selectEvent);
  const [selectedDateAndTime, setSelectedDateAndTime] =
    useState<Dayjs>(initialDateAndTime);
  const [selectedType, setSelectedType] = useState<MeetingTypes>(initialType);

  const startDate = useMemo(
    () => dayjs(event.startDate).add(1, "day"),
    [event]
  );
  const endDate = useMemo(
    () =>
      selectedType === MeetingTypes.InPerson
        ? dayjs(event.endDate)
        : dayjs(event.endDate).add(1, "month"),
    [event, selectedType]
  );

  useEffect(
    function setInitialStepOnMount() {
      setActiveStepIndex(initialStep);
    },
    [setActiveStepIndex]
  );

  useEffect(
    function updateInitialDateAndTimeOnEventLoad() {
      const { isDateInValidRange, validStartDate } = getValidDates(
        initialDateAndTime,
        [startDate, endDate]
      );

      !isDateInValidRange && setSelectedDateAndTime(validStartDate);
    },
    [endDate, startDate]
  );

  const handleSubmit = useSubmit({
    onAfterSubmit: () => setActiveStepIndex(activeStep + 1),
    selectedDateAndTime,
    selectedType,
    user,
  });

  const {
    date: selectedDate,
    isDateInValidRange: isSelectedDateInValidRange,
    validEndDate,
    validStartDate,
  } = getValidDates(selectedDateAndTime, [startDate, endDate]);

  const {
    isNoAvailableTimes,
    isTimeInValidRange: isSelectedTimeInValidRange,
    times,
  } = getValidTimes(
    selectedDateAndTime,
    event.meetingTimes[selectedType || defaultType]
  );

  const isSelectedDateNotAvailable =
    !isSelectedDateInValidRange || isNoAvailableTimes;

  const { contentElement, headerElement, footerElement } = useJourney({
    contentElements: [
      <TypeSelector onSelect={setSelectedType} value={selectedType} />,
      <DateSelector
        onSelect={(date: Dayjs) => {
          setSelectedDateAndTime(getDateAtMidnight(date));
        }}
        validEndDate={validEndDate}
        validStartDate={validStartDate}
        value={selectedDate}
      />,
      <TimeSelector
        onSelect={({ hour, minute }: TimeProps) => {
          setSelectedDateAndTime((previousValue) =>
            previousValue.set("hour", hour).set("minute", minute)
          );
        }}
        options={times}
        value={{
          hour: selectedDateAndTime.get("hour"),
          minute: selectedDateAndTime.get("minute"),
        }}
      />,
      <Note onSubmit={handleSubmit} />,
      <Confirmation inviteeFirstName={user.firstName} />,
    ],
    footerElements: (
      <Footer
        contentElement={
          activeStep === JourneySteps.TimeSelector && <TimeSelectorFooter />
        }
      />
    ),
    headerElements: (
      <Header
        date={activeStep > JourneySteps.DateSelector && selectedDateAndTime}
        dateFormat={
          activeStep > JourneySteps.TimeSelector
            ? humanReadableDateAndTimeFormat
            : humanReadableDateFormat
        }
        inviteeFullName={formatUserFullName(user)}
        type={activeStep > JourneySteps.TypeSelector && selectedType}
      />
    ),
    isConfirmationStepAdded: true,
    isNextStepBlocked:
      (activeStep === JourneySteps.TypeSelector && !isValue(selectedType)) ||
      (activeStep === JourneySteps.DateSelector &&
        isSelectedDateNotAvailable) ||
      (activeStep === JourneySteps.TimeSelector && !isSelectedTimeInValidRange),
  });

  return (
    <Window
      childrenKey={activeStep}
      footerElement={footerElement}
      headerElement={headerElement}
      isFullHeight
      isFullHeightChildrenVerticallyCentered
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      onClose={onClose}
    >
      {contentElement}
    </Window>
  );
};

export default (props: NewMeetingWindowProps) => {
  const componentElement = useUnmountOnAfterClose(NewMeetingWindow, props);

  return <JourneyContextProvider>{componentElement}</JourneyContextProvider>;
};
