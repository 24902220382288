import dayjs from "dayjs";
import { isLoaded } from "react-redux-firebase";

import {
  comparingDateAndTimeFormat,
  comparingTodaysEndDateAndTimeFormat,
} from "helpers/constants";
import type { MeetingProps } from "store";
import { FirestoreStatuses as Filters } from "views/Meetings";

import type { RootState } from "..";
import { selectEventFavouritedUsers } from "./event";

export * from "./event";

export const appState = (state: RootState) => state;

export const selectAccount = (state: RootState) => state.app.account;

export const selectAccountId = (state: RootState) => {
  console.log("DEBUG selectAccountId", state.firebase.auth.uid);
  return state.firebase.auth.uid;
};
export const selectAuthIsLoading = (state: RootState) =>
  state.firebase.auth.isLoaded === false;

export const selectAuthUserType = (state: RootState) => {
  const accountId = selectAccountId(state);

  const isAuthenticatedUser = !!accountId;
  const isGuestUser = !accountId;

  return { isAuthenticatedUser, isGuestUser };
};

export const selectCompanies = (state: RootState) => state.app.companies;

export const selectAllEvents = (state: RootState) => state.app.events.all;

export const selectJoinedEvents = (state: RootState) => state.app.events.joined;

export const selectIsUserFavourite = (state: RootState, userId: string) => {
  const favouritedUsers = selectEventFavouritedUsers(state);

  return isLoaded(favouritedUsers) && favouritedUsers.includes(userId);
};

export const selectMatches = (state: RootState) =>
  state.firestore.ordered.matches;

export const selectMeetings = (state: RootState) => state.app.meetings;

export const selectMeetingsToday = (state: RootState) => {
  const meetings = selectMeetings(state);
  const todaysEnd = dayjs().format(comparingTodaysEndDateAndTimeFormat);
  const rightNow = dayjs().format(comparingDateAndTimeFormat);

  const meetingsToday = meetings?.filter(
    ({ status, proposed_time }: MeetingProps) => {
      const date = dayjs(proposed_time.toDate()).format(
        comparingDateAndTimeFormat
      );

      return (
        status === Filters.Accepted && date >= rightNow && date <= todaysEnd
      );
    }
  );

  return meetingsToday;
};

export const selectMeetingsTodayCount = (state: RootState) => {
  const meetingsToday = selectMeetingsToday(state);

  return meetingsToday?.length;
};

export const selectMeetingRequestsReceived = (state: RootState) => {
  const accountId = selectAccountId(state);
  const meetings = selectMeetings(state);

  const received = meetings?.filter(
    ({ status, invitee_id }: MeetingProps) =>
      status === Filters.Pending && invitee_id === accountId
  );

  const sent = meetings?.filter(
    ({ status, inviter_id }: MeetingProps) =>
      status === Filters.Pending && inviter_id === accountId
  );

  return { received, sent };
};

export const selectMeetingRequestsReceivedCount = (state: RootState) => {
  const { received } = selectMeetingRequestsReceived(state);
  const rightNow = dayjs().format(comparingDateAndTimeFormat);

  return received?.filter((meeting) => {
    const meetingTime = dayjs(meeting.proposed_time.toDate()).format(
      comparingDateAndTimeFormat
    );
    return meetingTime > rightNow;
  }).length;
};

export const getFirebaseToken = (state: RootState) => state.app.firebaseToken;
