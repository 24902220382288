import { defaultUserInterests } from "views/Edit/Interests";

import type { EventInterestTagProps, RootState } from "..";

export const selectEvent = (state: RootState) => state.app.event;

export const selectEventChatTargetProfile = (state: RootState) =>
  state.app.event.chat.target.profile;

export const selectEventCompany = (state: RootState) => state.app.event.company;

export const selectEventCompanyProducts = (state: RootState) =>
  state.app.event.company.products;

export const selectEventFavouritedUsers = (state: RootState) =>
  state.app.event.favouritedUsers;

export const selectEventInterests = (state: RootState) =>
  state.app.event.interests;

export const selectEventInterestsAllTags = (state: RootState) => {
  const eventInterests = selectEventInterests(state);

  return eventInterests?.items.flatMap(({ tags }: any) =>
    tags.map(({ id }: EventInterestTagProps) => id)
  );
};

export const selectEventProfile = (state: RootState) => state.app.event.profile;

export const selectEventProfileInterests = (state: RootState) => {
  const { interests } = selectEventProfile(state);

  return { ...defaultUserInterests, ...interests };
};

export const selectEventProfileIsOnboardingFinished = (state: RootState) => {
  const { isOnboardingFinished } = selectEventProfile(state);

  return isOnboardingFinished;
};

export const selectEventProfileIsSingleEventAccess = (state: RootState) => {
  const { isSingleEventAccess } = selectEventProfile(state);

  return isSingleEventAccess;
};

export const selectEventProfileIsSingleEventAccessAndOnboardingNotFinished = (
  state: RootState
) => {
  const isOnboardingFinished = selectEventProfileIsOnboardingFinished(state);
  const isSingleEventAccess = selectEventProfileIsSingleEventAccess(state);

  return isSingleEventAccess && !isOnboardingFinished;
};

export const selectEventUsers = (state: RootState) => state.app.event.users;
