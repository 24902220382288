import { Button, ConfigProvider, Row, Skeleton, theme } from "antd";

import {
  Avatar,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { ExternalOutlined, IconSizes } from "components/icons";
import { isEmpty } from "helpers";
import { useSelector } from "hooks";
import { selectEventCompany } from "store/selectors";

import { StyledCardPlaceholder } from "./ProductItem";
import { Products } from "./Products";

export const logoSize = 64;

type ContentProps = {
  isLoading: boolean;
};

const Content = ({ isLoading }: ContentProps) => {
  const eventCompany = useSelector(selectEventCompany);
  const { token } = theme.useToken();

  const logoElement = isLoading ? (
    <Skeleton.Avatar size={logoSize} />
  ) : (
    <Avatar size={logoSize} src={eventCompany.image} />
  );

  const buttonElement = isLoading ? (
    <Skeleton.Button shape="round" style={{ width: 172 }} />
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: token.colorTextBase,
            fontSize: TypographySizes.SM,
          },
          Typography: {
            colorText: token.colorTextLightSolid,
          },
        },
      }}
    >
      <Button
        href={eventCompany.exhibitorUrl}
        shape="round"
        target="_blank"
        type="primary"
      >
        <Space>
          <Typography.Text size={TypographySizes.SM} strong>
            View company profile
          </Typography.Text>
          <ExternalOutlined
            fill={token.colorTextLightSolid}
            size={IconSizes.SM}
          />
        </Space>
      </Button>
    </ConfigProvider>
  );

  const errorElement = (
    <Space direction="vertical" type={SpaceTypes.Centered}>
      <Typography.Title level={3}>
        We were unable to load this company
      </Typography.Title>
      <Typography.Text size={TypographySizes.SM}>
        Please contact our support team to resolve this.
      </Typography.Text>
    </Space>
  );

  const productsElement = isLoading ? (
    <Row gutter={[20, 20]}>
      <StyledCardPlaceholder />
      <StyledCardPlaceholder />
    </Row>
  ) : (
    <>
      {eventCompany.externalId && (
        <Products companyExternalId={eventCompany.externalId} />
      )}
    </>
  );

  return isEmpty(eventCompany) ? (
    errorElement
  ) : (
    <Space direction="vertical" size={token.sizeXXL}>
      <Space direction="vertical" size="middle" type={SpaceTypes.Centered}>
        {logoElement}
        {buttonElement}
        <Typography.Text>{eventCompany.additionalInfo}</Typography.Text>
      </Space>
      {productsElement}
    </Space>
  );
};

export default Content;
