import { useState } from "react";

import ProfileCard from "components/ProfileCard";
import { isLoaded } from "helpers";
import { useSelector } from "hooks";
import { selectEventProfile } from "store/selectors";

import CompanyWindow from "./CompanyWindow";

export const maxWidth = 450;

const PreviewProfile = () => {
  const eventProfile = useSelector(selectEventProfile);
  const [isCompanyWindowOpen, setIsCompanyWindowOpen] =
    useState<boolean>(false);

  return (
    <>
      <div style={{ margin: "auto", maxWidth }}>
        <ProfileCard
          isLoading={!isLoaded(eventProfile)}
          onBoothButtonClick={() => setIsCompanyWindowOpen(true)}
          user={eventProfile}
        />
      </div>

      <CompanyWindow
        companyId={eventProfile?.companyId}
        isOpen={isCompanyWindowOpen}
        onClose={() => setIsCompanyWindowOpen(false)}
      />
    </>
  );
};

export default PreviewProfile;
