import React from "react";
import { Provider } from "react-redux";

import { FirebaseProvider } from "./database/firebase";
import Routes from "./routes";
import { store } from "./store";

const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <FirebaseProvider store={store}>
          <Routes />
        </FirebaseProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
