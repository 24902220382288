import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Talk from "talkjs";

import { formatUserFullName } from "helpers";
import { useDispatch, useSelector } from "hooks";
import { fetchEventChatTargetProfile } from "store/appSlice";
import {
  selectAccountId,
  selectEventChatTargetProfile,
  selectEventProfile,
} from "store/selectors";

import styles from "./ChatBox.module.scss";

const Chat = () => {
  const dispatch = useDispatch();
  const { eventId, targetUserId } = useParams();
  const [talkLoaded, markTalkLoaded] = useState(false);
  const accountId = useSelector(selectAccountId);
  const eventChatTargetProfile = useSelector(selectEventChatTargetProfile);
  const eventProfile = useSelector(selectEventProfile);
  const inboxEl = useRef();

  useEffect(() => {
    dispatch(fetchEventChatTargetProfile(targetUserId, eventId));
  }, [dispatch, eventId, targetUserId]);

  Talk.ready.then(() => markTalkLoaded(true));

  const currentUserIdWithEventId = accountId + eventId;
  const targetUserIdWithEventId = targetUserId + eventId;

  useEffect(() => {
    if (talkLoaded && eventProfile?.isLoaded) {
      const currentUserData = new Talk.User({
        id: currentUserIdWithEventId,
        name: formatUserFullName(eventProfile),
        email: eventProfile.email,
        ...(eventProfile?.avatarUrl && {
          photoUrl: eventProfile?.avatarUrl,
        }),
        role: "default",
      });

      const session = new Talk.Session({
        appId: process.env.REACT_APP_TalkJsAppId,
        me: currentUserData,
      });

      const inbox = session.createInbox();
      inbox.mount(inboxEl.current);

      if (targetUserId && eventChatTargetProfile) {
        const targetUserData = new Talk.User({
          id: targetUserIdWithEventId,
          name: formatUserFullName(eventChatTargetProfile),
          email: eventChatTargetProfile.email,
          photoUrl: eventChatTargetProfile?.avatarUrl,
          role: "default",
        });

        const conversationId = Talk.oneOnOneId(currentUserData, targetUserData);
        const conversation = session.getOrCreateConversation(conversationId);
        conversation.setParticipant(currentUserData);
        conversation.setParticipant(targetUserData);

        inbox.select(conversation);
      }

      return () => session.destroy();
    }
  }, [
    accountId,
    currentUserIdWithEventId,
    eventChatTargetProfile,
    eventId,
    eventProfile,
    talkLoaded,
    targetUserId,
    targetUserIdWithEventId,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inbox} ref={inboxEl} />
    </div>
  );
};

export default Chat;
