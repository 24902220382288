import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { serverTimestamp } from "firebase/firestore";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

import sendEmail from "database/handles/sendEmail";
import { formatUserFullName, getFirestoreTimestamp } from "helpers";
import {
  forwardSlashedEuropeanDateFormat,
  timeFormat,
} from "helpers/constants";
import { MeetingTypes } from "helpers/enums";
import { useDatabase, useSelector } from "hooks";
import { ProfileProps } from "store";
import { selectEventProfile } from "store/selectors";

import { createEventMeeting } from "../../../api/resources/events";
import { FieldValues } from "./Content/Note";

type SubmitProps = {
  onAfterSubmit: () => void;
  selectedDateAndTime: Dayjs;
  selectedType: MeetingTypes;
  user: ProfileProps;
};

export const useSubmit = ({
  onAfterSubmit,
  selectedDateAndTime,
  selectedType,
  user,
}: SubmitProps) => {
  const database = useDatabase();
  const { eventId } = useParams();
  const eventProfile = useSelector(selectEventProfile);

  return useCallback(
    async (values) => {
      const requestData = {
        archived: {
          [user.id]: false,
          [eventProfile.id]: false,
        },
        created_at: serverTimestamp(),
        eventId,
        inviter_id: eventProfile.id,
        invitee_id: user.id,
        inviter: eventProfile.email,
        invitee: user.email,
        inviter_first_name: eventProfile.firstName,
        inviter_last_name: eventProfile.lastName,
        invitee_first_name: user.firstName,
        invitee_last_name: user.lastName,
        message: values[FieldValues.Note] || "",
        proposed_time: getFirestoreTimestamp(selectedDateAndTime),
        status: "pending",
        type: selectedType,
      };

      const emailData = {
        inviteeName: user.firstName,
        inviterName: formatUserFullName(eventProfile),
        date: dayjs(selectedDateAndTime).format(
          forwardSlashedEuropeanDateFormat
        ),
        time: dayjs(selectedDateAndTime).format(timeFormat),
        meetingNote: values[FieldValues.Note] || "No meeting note added.",
      };

      await database
        .collection("meetings")
        .add(requestData)
        .then((meeting) => {
          const meetingData = {
            inviteeId: user.id,
            inviterId: eventProfile.id,
            proposedTime: selectedDateAndTime.toISOString(),
            message: values[FieldValues.Note] || null,
            type:
              selectedType === MeetingTypes.InPerson ? "in-person" : "virtual",
            firebaseMeetingId: meeting.id,
          };

          createEventMeeting(eventId, meetingData);
          onAfterSubmit();
        })
        .then(() => {
          sendEmail(
            user.email,
            emailData,
            "d-083fed09e31c4c6eae8f46dea4b0539b"
          );
        });
    },
    [
      database,
      eventId,
      eventProfile,
      onAfterSubmit,
      selectedDateAndTime,
      selectedType,
      user,
    ]
  );
};
