import { Col, Row, Skeleton, Space, theme } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Window from "components/Window";
import { Card } from "components/antd";
import { formatUserFullName, isLoaded } from "helpers";
import { useDispatch, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import type { ProfileProps } from "store";
import { fetchAllEventUsers } from "store/appSlice";
import {
  selectAccountId,
  selectEventFavouritedUsers,
  selectEventProfile,
  selectEventUsers,
} from "store/selectors";

import Body, { BodyRow } from "./Body";
import Export from "./Export";
import Filters, { FilterValues } from "./Filters";
import NetworkingPreferences from "./NetworkingPreferences";

export const Loading = () => (
  <Col span={24} md={12}>
    <Card bordered={false} loading />
  </Col>
);

const Connect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filter = FilterValues.Matches, eventId } = useParams();
  const accountId = useSelector(selectAccountId);
  const eventProfile = useSelector(selectEventProfile);
  const eventUsers = useSelector(selectEventUsers);
  const userFavoritesUserList = useSelector(selectEventFavouritedUsers);
  // const [activeFilter, setActiveFilter] = useState<FilterValues>(
  //   FilterValues.Matches
  // );
  const [isExportWindowOpen, setIsExportWindowOpen] = useState(false);
  const [
    isNetworkingPreferencesWindowOpen,
    setIsNetworkingPreferencesWindowOpen,
  ] = useState(false);
  const [
    isWaitingForEventProfileInterestsUpdate,
    setIsWaitingForEventProfileInterestsUpdate,
  ] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { token } = theme.useToken();

  const handleEventProfileInterestsChange = () => {
    setIsWaitingForEventProfileInterestsUpdate(false);
  };

  useEffect(handleEventProfileInterestsChange, [
    eventProfile?.interests?.network,
  ]);

  const handleFilterClick = useCallback(
    (filterValue: FilterValues) => {
      navigate(`/${eventId}/${ProtectedPaths.EventConnect}/${filterValue}`);
    },
    [eventId, navigate]
  );

  const handleNetworkPreferencesChange = () => {
    setIsNetworkingPreferencesWindowOpen(false);
    setIsWaitingForEventProfileInterestsUpdate(true);
  };

  useEffect(() => {
    dispatch(fetchAllEventUsers(accountId, eventId));
  }, [accountId, eventId, dispatch]);

  const onChangeFilter = useCallback(
    (filterValue: FilterValues) => {
      dispatch(fetchAllEventUsers(accountId, eventId));
      handleFilterClick(filterValue);
    },
    [accountId, dispatch, eventId, handleFilterClick]
  );

  const isFavoritesFilterActive =
    (filter as FilterValues) === FilterValues.Favourites;

  const filteredUsers: ProfileProps[] = useMemo(() => {
    let usersExcludingViewer: ProfileProps[] = eventUsers
      ? eventUsers.filter(
          (match) =>
            match.id !== accountId && match.interests?.business.length > 0
        )
      : [];

    if (searchKeyword) {
      usersExcludingViewer = usersExcludingViewer.filter((user) => {
        const fullName = formatUserFullName(user);

        return (
          fullName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          user.companyName?.toLowerCase().includes(searchKeyword.toLowerCase())
        );
      });
    }

    if (isFavoritesFilterActive) {
      usersExcludingViewer = usersExcludingViewer.filter(
        (match: ProfileProps) => {
          return userFavoritesUserList?.includes(match.id);
        }
      );
    }

    return usersExcludingViewer;
  }, [
    accountId,
    eventUsers,
    isFavoritesFilterActive,
    searchKeyword,
    userFavoritesUserList,
  ]);

  const isLoading =
    !isLoaded(eventUsers) || isWaitingForEventProfileInterestsUpdate;

  return (
    <>
      <Row>
        <Col span={24}>
          <Space direction="vertical" size={token.sizeXXL}>
            <Filters
              activeFilter={filter as FilterValues}
              onChangeFilter={onChangeFilter}
              onExportButtonClick={() => setIsExportWindowOpen(true)}
              onNetworkingPreferencesButtonClick={() =>
                setIsNetworkingPreferencesWindowOpen(true)
              }
              onSearchKeywordChange={setSearchKeyword}
              searchKeyword={searchKeyword}
            />

            {!isLoading ? (
              <Body
                activeFilter={filter as FilterValues}
                onFilterLinkClick={(filterValue: FilterValues) =>
                  handleFilterClick(filterValue)
                }
                onNetworkPreferencesLinkClick={() =>
                  setIsNetworkingPreferencesWindowOpen(true)
                }
                searchKeyword={searchKeyword}
                users={filteredUsers}
              />
            ) : (
              <>
                <BodyRow>
                  <Col span={12}>
                    <Skeleton.Input block size="small" />
                  </Col>
                </BodyRow>

                <BodyRow>
                  <Loading />
                  <Loading />
                </BodyRow>
              </>
            )}
          </Space>
        </Col>
      </Row>

      <Window
        isOpen={isExportWindowOpen}
        onClose={() => setIsExportWindowOpen(false)}
      >
        <Export
          isFavouritedUsers={filteredUsers.length > 0}
          onDone={() => setIsExportWindowOpen(false)}
        />
      </Window>
      <Window
        isOpen={isNetworkingPreferencesWindowOpen}
        onClose={() => setIsNetworkingPreferencesWindowOpen(false)}
      >
        <NetworkingPreferences onDone={handleNetworkPreferencesChange} />
      </Window>
    </>
  );
};

export default Connect;
