import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import { isEmpty, isLoaded } from "react-redux-firebase";

import type { ProfileProps, TimeProps, TimeWithAvailabilityProps } from "store";

import { firestoreTimestampFormat } from "./constants";

export const cssMultiplier = (css: string, multiplier: number) => {
  const multipliedCss = [...Array(multiplier)].map(() => css);

  return multipliedCss.join(",");
};

export const formatCountdownInDays = (startDate: Date, endDate: Date) => {
  const now = dayjs().startOf("day");
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const isFutureEvent = now.isBefore(start);
  const isPastEvent = now.isAfter(end);
  const dueValue = isPastEvent ? -1 : 0;

  return isFutureEvent ? start.diff(now, "day") : dueValue;
};

export const getDateAtMidnight = (date: Dayjs) =>
  date.set("hour", 0).set("minute", 0).set("second", 0);

export const getDateAtEndOfPreviousMonth = (date: Dayjs) => date.set("date", 0);

export const getDateAtStartOfNextMonth = (date: Dayjs) =>
  date.add(1, "month").set("date", 1);

export const getFirestoreTimestamp = (date: Dayjs) =>
  new Timestamp(
    Date.parse(new Date(date.format(firestoreTimestampFormat)).toISOString()) /
      1000,
    0
  );

export const formatDatesRange = (startDate: Date, endDate: Date) => {
  const start = dayjs(startDate).format("DD MMM");
  const end = dayjs(endDate).format("DD MMM, YYYY");

  return `${start} - ${end}`;
};

export const formatNotEmptyString = (string: string) =>
  string !== "" ? string : undefined;

export const formatTime = ({ hour, minute }: TimeProps, separator = ":") => {
  const paddedNumber = (timeUnitValue: number) =>
    String(timeUnitValue).padStart(2, "0");

  return `${paddedNumber(hour)}${separator}${paddedNumber(minute)}`;
};

export const formatUserCompanyNameWithJobTitle = (user: ProfileProps) => {
  const separator = user?.jobTitle && user?.companyName ? " • " : "";

  return (
    isLoaded(user) &&
    `${user?.jobTitle || ""}${separator}${user?.companyName || ""}`
  );
};

export const formatUserFullName = (user: ProfileProps) =>
  isLoaded(user) && `${user?.firstName} ${user?.lastName}`;

export const formatUserInitials = (user: ProfileProps) =>
  isLoaded(user) &&
  `${user?.firstName.charAt(0).toUpperCase()}${user?.lastName
    .charAt(0)
    .toUpperCase()}`;

type AccumulatorDimensionsProps = { height: number; width: number };

export const getChildrenDimensions = ({ children }: any) => {
  const initialDimensions = { height: 0, width: 0 };

  return children
    ? [...children].reduce(
        (
          accumulator: AccumulatorDimensionsProps,
          { clientHeight, clientWidth }: HTMLDivElement
        ) => ({
          height: accumulator.height + clientHeight,
          width: accumulator.width + clientWidth,
        }),
        initialDimensions
      )
    : initialDimensions;
};

export const getSubChildrenDimensions = (
  { children }: any,
  targetId: string
) => {
  const initialDimensions = { height: 0, width: 0 };

  return children
    ? [...children].reduce(
        (
          accumulator: AccumulatorDimensionsProps,
          { children: subChildren, id }: HTMLDivElement
        ) =>
          id === targetId
            ? {
                height: accumulator + getChildrenDimensions(subChildren).height,
                width: accumulator + getChildrenDimensions(subChildren).width,
              }
            : initialDimensions,
        initialDimensions
      )
    : initialDimensions;
};

export const getIsPastDate = (date: Dayjs) => date < dayjs().add(-1, "day");

export const getIsPastTime = (date: Dayjs) => date < dayjs();

export const getValidDates = (
  dateAndTime: Dayjs,
  [startDate, endDate]: Dayjs[]
) => {
  const date = getDateAtMidnight(dateAndTime);
  const validEndDate = getDateAtMidnight(endDate);
  const validStartDate = getDateAtMidnight(startDate);

  const isPastDate = getIsPastDate(date);
  const isDateInValidRange =
    !isPastDate &&
    dateAndTime.isBetween(validStartDate, validEndDate, "day", "[]");

  return {
    date,
    isDateInValidRange,
    validEndDate,
    validStartDate,
  };
};

export const isArray = (variable: any) => Array.isArray(variable);

export const isEqual = (
  variableOne: object | string[],
  variableTwo: object | string[]
) =>
  isArray(variableOne) || isArray(variableTwo)
    ? _.isEqual(_.sortBy(variableOne), _.sortBy(variableTwo))
    : _.isEqual(variableOne, variableTwo);

export const getValidTimes = (
  dateAndTime: Dayjs,
  meetingTimes: TimeProps[]
) => {
  const times = meetingTimes.map((meetingTime: TimeProps) => {
    const isPastMeetingTime = getIsPastTime(
      dateAndTime
        .set("hour", meetingTime.hour)
        .set("minute", meetingTime.minute)
    );

    return {
      ...meetingTime,
      isAvailable: !isPastMeetingTime,
    } as TimeWithAvailabilityProps;
  });

  const isNoAvailableTimes = !times.some(
    ({ isAvailable }: TimeWithAvailabilityProps) => isAvailable
  );
  const isPastTime = getIsPastTime(dateAndTime);
  const isTimeInValidRange =
    !isPastTime &&
    meetingTimes.some((meetingTime: TimeProps) =>
      isEqual(meetingTime, {
        hour: dateAndTime.get("hour"),
        minute: dateAndTime.get("minute"),
      })
    );

  return {
    isNoAvailableTimes,
    isTimeInValidRange,
    times,
  };
};

export const isFunction = (variable: any) => typeof variable === "function";

export const isNumber = (variable: any) =>
  !isNaN(variable) && variable !== null;

export const isObject = (variable: any) => typeof variable === "object";

export const isValue = (variable: any) =>
  variable !== undefined && variable !== null;

export { isEmpty, isLoaded };
