import {
  LogoutOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Space, theme } from "antd";
import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Link, Typography, TypographySizes } from "components/antd";
import { formatUserFullName } from "helpers";
import { useLogOut, usePath, useReturnPath, useSelector } from "hooks";
import { LayoutContext } from "layouts/Main";
import { ProtectedPaths } from "routes";
import { selectAccount } from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { colorGraySemi } from "styles/variables";

export const key = "general";

const StyledTypographyTextLabel = styled(Typography.Text)(
  ({ styledProps: { marginTop } }: StyledProps) => ({
    display: "block",
    marginTop,
  })
);

type GeneralProps = {
  onLinkClick: () => void;
};

const General = ({ onLinkClick }: GeneralProps) => {
  const { setIsGeneralViewVisible } = useContext(LayoutContext);
  const logOut = useLogOut();
  const navigate = useNavigate();
  const accountPath = usePath(ProtectedPaths.Account);
  const eventSupportPath = usePath(ProtectedPaths.EventSupport);
  const returnPath = useReturnPath();
  const account = useSelector(selectAccount);
  const { token } = theme.useToken();

  useEffect(() => {
    setIsGeneralViewVisible?.(true);

    return () => {
      setIsGeneralViewVisible?.(false);
    };
  }, [setIsGeneralViewVisible]);

  const handleLinkClick = useCallback(
    (to) => {
      navigate(to, { state: { returnPath } });

      onLinkClick();
    },
    [navigate, onLinkClick, returnPath]
  );

  return (
    <Space direction="vertical" size="middle">
      <div>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {formatUserFullName(account)}
        </Typography.Title>
        <Typography.Text color={colorGraySemi} size={TypographySizes.MD}>
          {account.email}
        </Typography.Text>
      </div>
      <StyledTypographyTextLabel
        size={TypographySizes.SM}
        styledProps={{ marginTop: token.margin }}
        uppercase
      >
        Account
      </StyledTypographyTextLabel>
      <Link onClick={() => handleLinkClick(accountPath)}>
        <Space>
          <UserOutlined /> Edit account
        </Space>
      </Link>
      <Link onClick={logOut}>
        <Space>
          <LogoutOutlined /> Log out
        </Space>
      </Link>
      <StyledTypographyTextLabel
        size={TypographySizes.SM}
        styledProps={{ marginTop: token.margin }}
        uppercase
      >
        Support
      </StyledTypographyTextLabel>
      <Link onClick={() => handleLinkClick(eventSupportPath)}>
        <Space>
          <MessageOutlined /> Message to support
        </Space>
      </Link>
    </Space>
  );
};

export default General;
