import type { CompanyProps, ProfileInterestsProps, ProfileProps } from "store";

type EventProfileProps = {
  avatarUrl: string;
  company: CompanyProps;
  companyId: string;
  countryCode: string;
  description: string;
  id: string;
  interest_tags: ProfileInterestsProps;
  isOnboardingFinished: boolean;
  jobTitle: string;
  linkedIn: string;
  phoneNumber: string;
};

type UserProps = {
  id: string;
  created_at: string;
  email: string;
  first_name: string;
  last_name: string;
};

export const formatDataToProfileProps = (
  user: UserProps,
  eventProfile: EventProfileProps
) => {
  const isCompanyListed = !!eventProfile?.company?.id;

  return {
    avatarUrl: eventProfile?.avatarUrl,
    companyId: isCompanyListed ? eventProfile.company.id : null,
    companyName: isCompanyListed
      ? eventProfile?.company.name
      : eventProfile?.company,
    countryCode: eventProfile?.countryCode,
    description: eventProfile?.description,
    email: user.email,
    exhibitorUrl: eventProfile?.company?.exhibitorUrl,
    firstName: user.first_name,
    id: user.id,
    interests: eventProfile?.interest_tags,
    isOnboardingFinished: eventProfile?.isOnboardingFinished,
    jobTitle: eventProfile?.jobTitle,
    lastName: user.last_name,
    linkedIn: eventProfile?.linkedIn,
    phoneNumber: eventProfile?.phoneNumber,
  } as ProfileProps;
};
