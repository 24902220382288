import { Skeleton, Space, message } from "antd";
import { useEffect, useState } from "react";

import { getCompany } from "api/resources/companies";
import { isFunction, isLoaded } from "helpers";
import { useSelector } from "hooks";
import { initialState } from "store/appSlice";
import { selectEventProfile } from "store/selectors";

import CompanyWindow from "../../CompanyWindow";
import ProfileForm from "./Form";

export const AvatarSkeleton = () => <Skeleton.Avatar active size={112} />;

type ProfileProps = {
  onSaveFinished?: () => void;
};

const Profile = ({ onSaveFinished }: ProfileProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const eventProfile = useSelector(selectEventProfile);
  const [companyFieldValue, setCompanyFieldValue] = useState<string>(
    eventProfile?.companyId
  );
  const [company, setCompany] = useState<any>();
  const [isCompanyWindowOpen, setIsCompanyWindowOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (companyFieldValue) {
      getCompany(companyFieldValue).then((listedCompany) => {
        const unlistedCompany = {
          ...initialState.event.company,
          name: companyFieldValue,
        };

        setCompany(listedCompany || unlistedCompany);
      });
    } else {
      setCompany(null);
    }
  }, [companyFieldValue, eventProfile?.companyId]);

  const handleSaveFinished = () => {
    messageApi.open({
      type: "success",
      content: "Your profile changes were saved.",
    });

    isFunction(onSaveFinished) && onSaveFinished();
  };

  return (
    <>
      {contextHolder}

      {isLoaded(eventProfile) ? (
        <ProfileForm
          company={company}
          onCompanyLinkClick={() => setIsCompanyWindowOpen(true)}
          onCompanySearchInputChange={(id) => setCompanyFieldValue(id)}
          onSaveFinished={handleSaveFinished}
        />
      ) : (
        <Space direction="vertical" size="large">
          <AvatarSkeleton />
          <Skeleton active />
        </Space>
      )}

      <CompanyWindow
        companyId={company?.id}
        isOpen={isCompanyWindowOpen}
        onClose={() => setIsCompanyWindowOpen(false)}
      />
    </>
  );
};

export default Profile;
