import { Tag } from "components/antd";
import { isLoaded } from "helpers";
import { useIsUserViewer, useSelector } from "hooks";
import type { ProfileProps } from "store";
import { selectEventInterests, selectEventProfile } from "store/selectors";

export type InterestTag = {
  id: string;
  label: object;
};

export const useMatchingInterests = (
  matchingUserEventProfile: ProfileProps
) => {
  const eventInterests = useSelector(selectEventInterests);
  const isMatchingUserViewer = useIsUserViewer(matchingUserEventProfile);
  const eventProfile = useSelector(selectEventProfile);

  const matchingInterests = isMatchingUserViewer
    ? []
    : eventProfile?.interests?.network.filter((interestTag: string) =>
        matchingUserEventProfile?.interests?.business?.includes(interestTag)
      );

  const userBusinessTagsWithMatchingInterests =
    (isLoaded(eventInterests) &&
      matchingUserEventProfile?.interests?.business
        ?.map((userBusinessInterestTagId: string) => {
          const eventInterestTags = eventInterests.items.reduce(
            (acc: any, current: any) => {
              return acc.concat(current.tags);
            },
            []
          );
          const tag = eventInterestTags.find(
            (eventInterestTag: any) =>
              eventInterestTag.id === userBusinessInterestTagId
          );
          const isMatching = matchingInterests?.includes(
            userBusinessInterestTagId
          );

          return {
            id: userBusinessInterestTagId,
            label: <Tag color={isMatching && "cyan"}>{tag?.name}</Tag>,
          };
        })
        .sort((a: InterestTag, b: InterestTag) => {
          if (matchingInterests?.includes(a.id)) return -1;
          if (matchingInterests?.includes(b.id)) return 1;
          return 0;
        })) ||
    [];

  return {
    isMatchingInterestsLoaded:
      isLoaded(eventInterests) && isLoaded(eventProfile),
    matchingInterestsCount: matchingInterests.length,
    userBusinessTagsWithMatchingInterests,
  };
};
