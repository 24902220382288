import React from "react";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import RouteTransition from "components/RouteTransition";
import { UncontrolledWindow } from "components/Window";
import MainLayout from "layouts/Main";
import FeedbackPage from "pages/Feedback";
import NotFoundPage from "pages/NotFound";
import SupportPage from "pages/Support";
import { ThemeProvider } from "styles";
import Connect from "views/Connect";
import EditAccount from "views/Edit/Account";
import EditInterests from "views/Edit/Interests";
import EditProfile from "views/Edit/Profile";
import Meetings from "views/Meetings";

import { AuthenticationGate } from "./Authentication";
import Entry from "./Authentication/Entry";
import Login from "./Authentication/Login";
import PasswordlessLogin from "./Authentication/PasswordlessLogin";
import Register from "./Authentication/Register";
import ResetPassword from "./Authentication/ResetPassword";
import ResetPasswordLanding from "./Authentication/ResetPasswordLanding";
import DeepLinkHandler from "./DeepLinkHandler";
import { EventGate, EventProvider } from "./Event";
import EventChat from "./Event/Chat";
import EventHome from "./Event/Home";
import EventOnboarding from "./Event/Onboarding";
import EventProfile from "./Event/Profile";
import EventProfileBadge from "./Event/ProfileBadge";
import Events from "./Events";

export enum UnprotectedPaths {
  "Entry" = "",
  "EventProfileBadge" = "badge",
  "Login" = "login",
  "PasswordlessLogin" = "passwordless-login",
  "Register" = "register",
  "ResetPassword" = "reset-password",
  "ResetPasswordLanding" = "set-password",
}

export enum ProtectedPaths {
  "Account" = "account",
  "EventChat" = "chat",
  "EventConnect" = "connect",
  "EventHome" = "home",
  "EventMeetings" = "meetings",
  "EventOnboarding" = "onboarding",
  "EventProfile" = "profile",
  "EventProfileEdit" = "profile/edit",
  "EventProfileInterests" = "profile/interests",
  "EventSupport" = "support",
  "EventSupportFeedback" = "support/feedback",
  "EventSupportManual" = "support/manual",
  "Events" = "events",
}

export type Match = string;
export type Path = ProtectedPaths | UnprotectedPaths;
export type ArrayWithPathAndFilters = (Path | Match)[];

export const getIsEventPath = (path: Path) => {
  return (
    path === ProtectedPaths.EventChat ||
    path === ProtectedPaths.EventConnect ||
    path === ProtectedPaths.EventHome ||
    path === ProtectedPaths.EventMeetings ||
    path === ProtectedPaths.EventOnboarding ||
    path === ProtectedPaths.EventProfile ||
    path === ProtectedPaths.EventProfileEdit ||
    path === ProtectedPaths.EventProfileInterests ||
    path === ProtectedPaths.EventSupport ||
    path === ProtectedPaths.EventSupportFeedback ||
    path === ProtectedPaths.EventSupportManual
  );
};

export const RedirectToEventProfileBadge = () => {
  const { eventId, targetUserId } = useParams();

  return (
    <Navigate
      to={`/${eventId}/${UnprotectedPaths.EventProfileBadge}/${targetUserId}`}
      replace
    />
  );
};

export default () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <DeepLinkHandler />
    <Routes>
      <Route
        path="/error"
        element={
          <ThemeProvider isDarkTheme>
            <NotFoundPage />
          </ThemeProvider>
        }
      />
      <Route path="/linkedin" element={<LinkedInCallback />} />
      <Route
        element={
          <RouteTransition>
            <Outlet />
          </RouteTransition>
        }
      >
        <Route
          element={
            <ThemeProvider isDarkTheme>
              <Outlet />
            </ThemeProvider>
          }
        >
          <Route
            element={
              <AuthenticationGate>
                <Outlet />
              </AuthenticationGate>
            }
          >
            <Route path={UnprotectedPaths.Entry} element={<Entry />} />
            <Route path={UnprotectedPaths.Login} element={<Login />} />
            <Route
              path={`/${UnprotectedPaths.PasswordlessLogin}/:token?`}
              element={<PasswordlessLogin />}
            />
            <Route path={UnprotectedPaths.Register} element={<Register />} />
            <Route
              path={UnprotectedPaths.ResetPassword}
              element={<ResetPassword />}
            />
            <Route
              path={UnprotectedPaths.ResetPasswordLanding}
              element={<ResetPasswordLanding />}
            />
          </Route>
          <Route
            element={
              <AuthenticationGate isProtectedPath>
                <Outlet />
              </AuthenticationGate>
            }
          >
            <Route path={ProtectedPaths.Account} element={<EditAccount />} />
            <Route
              path={`/${ProtectedPaths.Events}/:filter?`}
              element={<Events />}
            />
          </Route>
        </Route>
        <Route
          path=":eventId" // TODO: Rename eventId to eventPath
          element={
            <ThemeProvider>
              <Outlet />
            </ThemeProvider>
          }
        >
          <Route
            path="profilecard/:targetUserId"
            element={<RedirectToEventProfileBadge />}
          />
          <Route
            path={`${UnprotectedPaths.EventProfileBadge}/:targetUserId`}
            element={
              <EventProvider>
                <EventProfileBadge />
              </EventProvider>
            }
          />
          <Route
            element={
              <AuthenticationGate isProtectedPath>
                <EventProvider>
                  <EventGate>
                    <MainLayout>
                      <Outlet />
                    </MainLayout>
                  </EventGate>
                </EventProvider>
              </AuthenticationGate>
            }
          >
            <Route index element={<EventHome />} />
            <Route path={ProtectedPaths.EventHome} element={<EventHome />} />
            <Route
              path={`${ProtectedPaths.EventConnect}/:filter?`}
              element={
                <EventHome>
                  <Connect />
                </EventHome>
              }
            />

            <Route
              path={`${ProtectedPaths.EventMeetings}/:filter?`}
              element={
                <EventHome>
                  <Meetings />
                </EventHome>
              }
            />
            <Route
              path={`${ProtectedPaths.EventChat}/:targetUserId?`}
              element={<EventChat />}
            />
            <Route
              path={ProtectedPaths.EventProfile}
              element={<EventProfile />}
            />
            <Route
              path={ProtectedPaths.EventProfileEdit}
              element={
                <EventProfile>
                  <EditProfile />
                </EventProfile>
              }
            />
            <Route
              path={`${ProtectedPaths.EventProfileInterests}/:tab?`}
              element={
                <EventProfile>
                  <EditInterests />
                </EventProfile>
              }
            />
            <Route
              path={`${ProtectedPaths.EventOnboarding}/:step?/:tab?`}
              element={<EventOnboarding />}
            />
            <Route
              path={ProtectedPaths.EventSupportFeedback}
              element={
                <UncontrolledWindow noPadding>
                  <FeedbackPage />
                </UncontrolledWindow>
              }
            />
            <Route
              path={ProtectedPaths.EventSupport}
              element={<SupportPage />}
            />
          </Route>
        </Route>
        <Route
          path="/*"
          element={
            <ThemeProvider>
              <NotFoundPage />
            </ThemeProvider>
          }
        />
      </Route>
    </Routes>
  </BrowserRouter>
);
