import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  getCompanies,
  getCompany,
  getCompanyProducts,
} from "api/resources/companies";
import {
  getAllEvents,
  getEvent,
  getEventInterests,
  getJoinedEvents,
  updateUserEventProfile,
} from "api/resources/events";
import { getAllEventUsers, getEventUser } from "api/resources/users";
import { MeetingTypes } from "helpers/enums";
import { formatDataToProfileProps } from "helpers/store";
import { Thunk } from "store";

import type { AppProps, MeetingProps } from ".";

export const initialState: AppProps = {
  account: {
    createdAt: undefined,
    email: undefined,
    firstName: undefined,
    isEmpty: true,
    isLoaded: false,
    isTerminating: false,
    lastName: undefined,
  },
  event: {
    chat: {
      target: {
        profile: undefined,
      },
    },
    company: {
      name: undefined,
      id: undefined,
      image: undefined,
      isEmpty: false,
      isLoaded: false,
      additionalInfo: undefined,
      exhibitorUrl: undefined,
      externalId: undefined,
      products: undefined,
    },
    coverImageSrc: undefined,
    endDate: undefined,
    favouritedUsers: [],
    id: undefined,
    interests: { isEmpty: false, isLoaded: false, items: [] },
    isEmpty: false,
    isInPersonMeetingAvailable: false,
    isInPersonMeetingWithDedicatedTables: false,
    isLoaded: false,
    isLocked: undefined,
    isOnboardingStarted: undefined,
    isOnboardingFinished: undefined,
    meetingTimes: { [MeetingTypes.InPerson]: [], [MeetingTypes.Virtual]: [] },
    notificationsCount: undefined,
    path: undefined,
    profile: {
      avatarUrl: undefined,
      companyId: undefined,
      companyName: undefined,
      countryCode: undefined,
      description: undefined,
      id: undefined,
      interests: {
        business: [],
        network: [],
      },
      isEmpty: false,
      isLoaded: false,
      isOnboardingFinished: undefined,
      isSingleEventAccess: undefined,
      jobTitle: undefined,
      linkedIn: undefined,
      phoneNumber: undefined,
    },
    startDate: undefined,
    ticketUrl: undefined,
    title: undefined,
    users: undefined,
  },
  companies: [],
  events: { all: undefined, joined: undefined },
  meetings: undefined,
  error: null,
  firebaseToken: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAccountFailureReducer(state) {
      state.account.isEmpty = true;
      state.account.isLoaded = true;
    },
    fetchAccountInitialReducer(state) {
      state.account = initialState.account;
    },
    fetchAccountLoadingReducer(state) {
      state.account.isEmpty = false;
      state.account.isLoaded = false;
    },
    fetchAccountSuccessReducer(state, data) {
      const { profile: account } = data.payload;

      if (account) {
        state.account = {
          createdAt: account.created_at,
          email: account.email,
          firstName: account.first_name,
          isEmpty: false,
          isLoaded: true,
          lastName: account.last_name,
        };
      }
    },
    fetchAccountTerminatingReducer(state) {
      state.account.isTerminating = true;
    },
    fetchAllEventsReducer(state, data) {
      state.events.all = data.payload.map((event: any) => ({
        id: event.id,
        coverImageSrc: event.image,
        isLocked: event.isPrivate,
        isOnboardingStarted: false,
        isOnboardingFinished: false,
        endDate: new Date(event.endDate),
        path: event.alias,
        startDate: new Date(event.startDate),
        ticketUrl: "https://www.piletilevi.ee/",
        title: event.title,
        notificationsCount: 0,
      }));
    },
    fetchJoinedEventsReducer(state, data) {
      state.events.joined = data.payload.map((eventData: any) => ({
        id: eventData.event.id,
        coverImageSrc: eventData.event.image,
        isLocked: eventData.event.isPrivate,
        isOnboardingStarted: !eventData.profile.isOnboardingFinished,
        isOnboardingFinished: eventData.profile.isOnboardingFinished,
        endDate: new Date(eventData.event.endDate),
        path: eventData.event.alias,
        startDate: new Date(eventData.event.startDate),
        ticketUrl: "https://www.piletilevi.ee/",
        title: eventData.event.title,
        notificationsCount: 0,
      }));
    },
    fetchEventErrorReducer(state) {
      state.event = {
        ...initialState.event,
        isEmpty: true,
        isLoaded: true,
      };
    },
    fetchEventReducer(state, data) {
      const {
        event,
        favoriteUsers,
        profile: eventProfile,
        ...user
      } = data.payload;

      state.event.coverImageSrc = event.image;
      state.event.endDate = new Date(event.endDate);
      state.event.favouritedUsers = favoriteUsers;
      state.event.id = event.id;
      state.event.isEmpty = false;
      state.event.isInPersonMeetingAvailable = true;
      state.event.isInPersonMeetingWithDedicatedTables = true;
      state.event.isLoaded = true;
      state.event.isLocked = event.isPrivate;
      state.event.isOnboardingStarted = false;
      state.event.isOnboardingFinished = false;
      state.event.meetingTimes = {
        [MeetingTypes.InPerson]: [
          { hour: 9, minute: 0 },
          { hour: 9, minute: 30 },
          { hour: 10, minute: 0 },
          { hour: 10, minute: 30 },
          { hour: 11, minute: 0 },
          { hour: 11, minute: 30 },
          { hour: 12, minute: 0 },
          { hour: 12, minute: 30 },
          { hour: 13, minute: 0 },
          { hour: 13, minute: 30 },
          { hour: 14, minute: 0 },
          { hour: 14, minute: 30 },
          { hour: 15, minute: 0 },
          { hour: 15, minute: 30 },
          { hour: 16, minute: 0 },
          { hour: 16, minute: 30 },
        ],
        [MeetingTypes.Virtual]: [
          { hour: 8, minute: 0 },
          { hour: 8, minute: 30 },
          { hour: 9, minute: 0 },
          { hour: 9, minute: 30 },
          { hour: 10, minute: 0 },
          { hour: 10, minute: 30 },
          { hour: 11, minute: 0 },
          { hour: 11, minute: 30 },
          { hour: 12, minute: 0 },
          { hour: 12, minute: 30 },
          { hour: 13, minute: 0 },
          { hour: 13, minute: 30 },
          { hour: 14, minute: 0 },
          { hour: 14, minute: 30 },
          { hour: 15, minute: 0 },
          { hour: 15, minute: 30 },
          { hour: 16, minute: 0 },
          { hour: 16, minute: 30 },
          { hour: 17, minute: 0 },
          { hour: 17, minute: 30 },
          { hour: 18, minute: 0 },
          { hour: 18, minute: 30 },
          { hour: 19, minute: 0 },
          { hour: 19, minute: 30 },
          { hour: 20, minute: 0 },
        ],
      };
      state.event.notificationsCount = 0;
      state.event.path = event.alias;
      state.event.profile = {
        ...formatDataToProfileProps(user, eventProfile),
        isEmpty: false,
        isLoaded: true,
      };
      state.event.startDate = new Date(event.startDate);
      state.event.ticketUrl = "https://www.piletitasku.ee/et/performance/21838";
      state.event.title = event.title;
    },
    fetchEventInterestsReducer(state, data) {
      state.event.interests = {
        isEmpty: false,
        isLoaded: true,
        items: data.payload,
      };
    },
    fetchCompaniesReducer(state, data) {
      state.companies = data.payload.companies.map((company: any) => ({
        id: company.id,
        image: company.logoImage,
        name: company.name,
        additionalInfo: company.additionalInfo,
        exhibitorUrl: company.exhibitorUrl,
        externalId: company.externalId,
      }));
    },
    fetchCompanyReducer(state, data) {
      const company = data.payload;

      state.event.company = {
        id: company.id,
        image: company.logoImage,
        isEmpty: false,
        isLoaded: true,
        name: company.name,
        additionalInfo: company.additionalInfo,
        exhibitorUrl: company.exhibitorUrl,
        externalId: company.externalId,
        products: company.products,
      };
    },
    fetchCompanyErrorReducer(state) {
      state.event.company = {
        ...initialState.event.company,
        isEmpty: true,
        isLoaded: true,
      };
    },
    fetchCompanyProductsReducer(state, data) {
      state.event.company.products = data.payload.products.map(
        (product: any) => ({
          id: product.id,
          name: product.name,
          image: product.image,
          description: product.description,
          externalId: product.externalId,
          url: product.url,
        })
      );
    },
    fetchAllEventUsersReducer(state, data) {
      state.event.users = data.payload.map((userData: any) => {
        const { eventProfile, ...user } = userData;

        return formatDataToProfileProps(user, eventProfile);
      });
    },
    fetchEventChatTargetProfileReducer(state, data) {
      const { eventProfile, ...user } = data.payload;

      state.event.chat.target.profile = formatDataToProfileProps(
        user,
        eventProfile
      );
    },
    unsetEvents(state) {
      state.events = initialState.events;
    },
    unsetEvent(state) {
      state.event = initialState.event;
    },
    unsetEventCompany(state) {
      state.event.company = initialState.event.company;
    },
    unsetMeetings(state) {
      state.meetings = initialState.meetings;
    },
    updateMeetings(state, action) {
      if (!state.meetings) state.meetings = [];

      action.payload.map((meeting: MeetingProps) => {
        const index = _.findIndex(state.meetings, { id: meeting.id });

        if (index !== -1) {
          state.meetings[index] = meeting;
        } else {
          state.meetings.push(meeting);
        }
      });

      state.meetings = state.meetings.map((meeting: MeetingProps) => ({
        ...meeting,
        type: meeting.type || ("Virtual" as MeetingTypes),
      }));

      return state;
    },
    apiErrorReceived(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    fetchFirebaseToken(state, data) {
      console.log(data);
      state.firebaseToken = data.payload;
    },
  },
});

export const {
  fetchAccountFailureReducer,
  fetchAccountInitialReducer,
  fetchAccountLoadingReducer,
  fetchAccountSuccessReducer,
  fetchAccountTerminatingReducer,
  fetchEventErrorReducer,
  fetchEventReducer,
  fetchAllEventsReducer,
  fetchJoinedEventsReducer,
  fetchAllEventUsersReducer,
  fetchEventChatTargetProfileReducer,
  fetchEventInterestsReducer,
  fetchCompaniesReducer,
  fetchCompanyReducer,
  fetchCompanyErrorReducer,
  fetchCompanyProductsReducer,
  fetchFirebaseToken,
  unsetEvents,
  unsetEvent,
  unsetEventCompany,
  unsetMeetings,
  updateMeetings,
} = appSlice.actions;

export const fetchAllEvents = (): Thunk => (dispatch) => {
  getAllEvents()
    .then(({ events }) => {
      dispatch(fetchAllEventsReducer(events));
    })
    .catch((err) => console.log(err));
};

export const fetchJoinedEvents =
  (userId: string): Thunk =>
  (dispatch) => {
    getJoinedEvents(userId)
      .then(({ events }) => {
        dispatch(fetchJoinedEventsReducer(events));
      })
      .catch((err) => console.log(err));
  };

export const fetchEvent =
  (userId: string, eventId: string): Thunk =>
  (dispatch) => {
    getEvent(userId, eventId)
      .then((result) => {
        dispatch(fetchEventReducer(result));
      })
      .catch((err) => {
        console.log(err);

        dispatch(fetchEventErrorReducer());
      });
  };

export const fetchUserEventUpdateProfile =
  (userId: string, eventId: string, data: any): Thunk =>
  (dispatch) => {
    updateUserEventProfile(userId, eventId, data)
      .then((result) => {
        dispatch(fetchEventReducer(result));
      })
      .catch((err) => console.log(err));
  };

export const fetchCompanies = (): Thunk => (dispatch) => {
  getCompanies()
    .then((result) => {
      dispatch(fetchCompaniesReducer(result));
    })
    .catch((err) => console.log(err));
};

export const fetchCompany =
  (companyId: string): Thunk =>
  (dispatch) => {
    getCompany(companyId)
      .then((company) => {
        dispatch(
          !company ? fetchCompanyErrorReducer() : fetchCompanyReducer(company)
        ); // TODO: Remove fetchCompanyErrorReducer() if response code 200 is not returned for invalid companyID
      })
      .catch((err) => {
        console.log(err);

        dispatch(fetchCompanyErrorReducer());
      });
  };

export const fetchEventInterests =
  (eventId: string): Thunk =>
  (dispatch) => {
    getEventInterests(eventId)
      .then((result) => {
        dispatch(fetchEventInterestsReducer(result));
      })
      .catch((err) => console.log(err));
  };

export const fetchCompanyProducts =
  (companyExternalId: string): Thunk =>
  (dispatch) => {
    getCompanyProducts(companyExternalId)
      .then((products) => {
        dispatch(fetchCompanyProductsReducer(products));
      })
      .catch((err) => console.log(err));
  };

export const setFirebaseToken =
  (token: string): Thunk =>
  (dispatch) => {
    dispatch(fetchFirebaseToken(token));
  };

export const fetchAllEventUsers =
  (userId: string, eventId: string): Thunk =>
  (dispatch) => {
    getAllEventUsers(userId, eventId)
      .then(({ users }) => {
        dispatch(fetchAllEventUsersReducer(users));
      })
      .catch((err) => console.log(err));
  };

export const fetchEventChatTargetProfile =
  (userId: string, eventId: string): Thunk =>
  (dispatch) => {
    getEventUser(userId, eventId)
      .then((user) => {
        dispatch(fetchEventChatTargetProfileReducer(user));
      })
      .catch((err) => console.log(err));
  };

export default appSlice.reducer;
